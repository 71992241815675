import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white shadow rounded-lg">
          <div className="p-8">
            <h1 className="text-4xl font-bold mb-4 text-gray-900">
              Privacy Policy
            </h1>
            <p className="text-sm text-gray-500 mb-8">
              Last Updated: November 2, 2024
            </p>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                1. Introduction
              </h2>
              <p className="text-gray-600 mb-4">
                Welcome to Bereavemently ("we," "our," or "us"). We understand
                that sharing grief experiences requires extraordinary trust.
                This Privacy Policy explains in detail how we collect, use,
                protect, and handle your personal information when you use our
                website (bereavemently.ai) and AI grief support services.
              </p>
              <p className="text-gray-600 mb-4">
                By using Bereavemently, you consent to the data practices
                described in this policy. We are committed to protecting your
                privacy in accordance with the General Data Protection
                Regulation (GDPR), California Consumer Privacy Act (CCPA), and
                other applicable privacy laws.
              </p>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                2. Information We Collect
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                2.1 Personal Information
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>
                  Account Information:
                  <ul className="list-circle pl-6 mt-2 space-y-1">
                    <li>Email address</li>
                    <li>Username</li>
                    <li>Password (encrypted)</li>
                    <li>Display name</li>
                    <li>Account preferences</li>
                  </ul>
                </li>
                <li>
                  Optional Profile Information:
                  <ul className="list-circle pl-6 mt-2 space-y-1">
                    <li>Age range</li>
                    <li>Geographic location (country/region)</li>
                    <li>Language preferences</li>
                    <li>Time zone</li>
                    <li>Profile picture</li>
                  </ul>
                </li>
                <li>
                  Grief Support Information:
                  <ul className="list-circle pl-6 mt-2 space-y-1">
                    <li>Nature of loss</li>
                    <li>Relationship to the deceased</li>
                    <li>Time since loss</li>
                    <li>Support preferences</li>
                  </ul>
                </li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                2.2 Conversation Data
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Chat transcripts with our AI system</li>
                <li>Emotional content analysis</li>
                <li>Support topics discussed</li>
                <li>Interaction patterns</li>
                <li>Response ratings and feedback</li>
                <li>Custom preferences for AI interactions</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                2.3 Technical Data
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>IP address</li>
                <li>Browser type and version</li>
                <li>Device information</li>
                <li>Operating system</li>
                <li>Session duration and timing</li>
                <li>Click patterns and navigation</li>
                <li>Error logs</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                3. How We Use Your Information
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                3.1 Primary Purposes
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>
                  To provide personalized grief support through our AI system
                </li>
                <li>To authenticate and maintain your account</li>
                <li>To improve our AI's understanding and responses</li>
                <li>To ensure continuity of support across sessions</li>
                <li>
                  To identify potential crisis situations and provide
                  appropriate resources
                </li>
                <li>
                  To maintain conversation history for context and continuity
                </li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                3.2 Secondary Purposes
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Service improvement and optimization</li>
                <li>
                  Research and development of grief support techniques (using
                  anonymized data)
                </li>
                <li>Statistical analysis of user needs and patterns</li>
                <li>Training and improving our AI models</li>
                <li>Detecting and preventing fraud or abuse</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                4. Data Protection Measures
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                4.1 Technical Safeguards
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>End-to-end encryption for all conversations</li>
                <li>256-bit AES encryption for stored data</li>
                <li>Multi-factor authentication options</li>
                <li>Regular security audits and penetration testing</li>
                <li>Automated threat detection and prevention</li>
                <li>Regular backup and disaster recovery procedures</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                4.2 Organizational Safeguards
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Strict access controls for employee data access</li>
                <li>Regular employee privacy training</li>
                <li>Data handling protocols and procedures</li>
                <li>Incident response plan</li>
                <li>Regular compliance audits</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                5. Data Sharing and Third Parties
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                5.1 Data Sharing
              </h3>
              <p className="text-gray-600 mb-4">We share your data with:</p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>
                  Cloud Service Providers:
                  <ul className="list-circle pl-6 mt-2 space-y-1">
                    <li>For secure data storage</li>
                    <li>For AI processing capabilities</li>
                    <li>For analytics and performance monitoring</li>
                  </ul>
                </li>
                <li>
                  Security Services:
                  <ul className="list-circle pl-6 mt-2 space-y-1">
                    <li>For fraud prevention</li>
                    <li>For threat detection</li>
                    <li>For security monitoring</li>
                  </ul>
                </li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                5.2 Third-Party Requirements
              </h3>
              <p className="text-gray-600 mb-4">All third parties must:</p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Meet our strict security standards</li>
                <li>Sign data processing agreements</li>
                <li>Comply with privacy laws and regulations</li>
                <li>Maintain confidentiality</li>
                <li>Process data only for specified purposes</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                6. User Rights and Controls
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                6.1 Your Rights
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Access your personal data</li>
                <li>Correct inaccurate data</li>
                <li>Request data deletion</li>
                <li>Restrict data processing</li>
                <li>Object to data processing</li>
                <li>Data portability</li>
                <li>Withdraw consent</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                6.2 Exercise Your Rights
              </h3>
              <p className="text-gray-600 mb-4">
                You can exercise your rights by:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Using the privacy controls in your account settings</li>
                <li>Contacting our Data Protection Officer</li>
                <li>Submitting a request through our privacy portal</li>
                <li>Emailing privacy@bereavemently.ai</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                7. Data Retention and Deletion
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                7.1 Retention Periods
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Active account data: Until account deletion</li>
                <li>Conversation history: 24 months (configurable)</li>
                <li>Technical logs: 90 days</li>
                <li>Analytics data: 36 months</li>
                <li>Backup data: 90 days</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                7.2 Deletion Procedures
              </h3>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Account deletion request processing within 30 days</li>
                <li>Permanent deletion from active systems</li>
                <li>Removal from backups within 90 days</li>
                <li>Certificate of deletion available upon request</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                8. International Data Transfers
              </h2>
              <p className="text-gray-600 mb-4">
                We process data globally using cloud services. International
                transfers are protected by:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Standard Contractual Clauses (SCCs)</li>
                <li>EU-US Privacy Shield compliance</li>
                <li>Data Transfer Impact Assessments</li>
                <li>Regional data storage options</li>
                <li>Cross-border transfer safeguards</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                9. Special Circumstances
              </h2>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                9.1 Legal Requirements
              </h3>
              <p className="text-gray-600 mb-4">
                We may disclose information when:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Required by law</li>
                <li>Responding to legal requests</li>
                <li>Protecting our rights</li>
                <li>Preventing harm</li>
              </ul>

              <h3 className="text-xl font-semibold mb-3 text-gray-700">
                9.2 Emergency Situations
              </h3>
              <p className="text-gray-600 mb-4">
                We may share information with emergency services if:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>We detect imminent risk of self-harm</li>
                <li>We identify threats to others</li>
                <li>Emergency medical attention may be needed</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                10. Changes and Updates
              </h2>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Policy updates notification via email</li>
                <li>30-day notice for material changes</li>
                <li>Version history available</li>
                <li>Change log maintained</li>
              </ul>
            </section>

            <section className="mb-12">
              <h2 className="text-2xl font-semibold mb-4 text-gray-800">
                11. Contact Information
              </h2>
              <p className="text-gray-600 mb-4">
                For privacy-related inquiries:
              </p>
              <ul className="list-disc pl-6 text-gray-600 space-y-2 mb-6">
                <li>Data Protection Officer: dpo@bereavemently.ai</li>
                <li>Privacy Team: privacy@bereavemently.ai</li>
                <li>General Support: support@bereavemently.ai</li>
                <li>Address: [Your Business Address]</li>
                <li>Phone: [Your Business Phone]</li>
              </ul>
            </section>

            <footer className="mt-12 pt-8 border-t border-gray-200">
              <p className="text-sm text-gray-500">
                © 2024 Bereavemently. All rights reserved.
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
